import request from '@/utils/request.js'

export function userFeedback(data) {
          return request({
                    url: '/api/v1/user-feedback',
                    method: 'post',
                    data
          })
}

export function putUserFeedback(data) {
          return request({
                    url: '/api/v1/user-feedback',
                    method: 'put',
                    data
          })
}

export function getUserFeedback(params) {
          return request({
                    url: '/api/v1/user-feedback',
                    method: 'get',
                    params
          })
}

export function uploadFile(data) {
          return request({
                    url: '/api/v1/public/uploadFile',
                    method: 'post',
                    data
          })
}

export function getInfo(params) {
          return request({
                    url: '/api/v1/user-feedback/info',
                    method: 'get',
                    params
          })
}

export function support() {
          return request({
                    url: '/api/v1/user-feedback/mail-tmpl',
                    method: 'get'
          })
}

export function log(data) {
          return request({
                    url: '/api/v1/user-feedback/log-path',
                    method: 'post',
                    data
          })
}

export function letter(data) {
          return request({
                    url: '/api/v1/user-feedback/letter',
                    method: 'post',
                    data
          })
}