// import VueRouter from "vue-router";
import {
          createRouter,
          createWebHistory
} from "vue-router";
import {
          log
} from "@/api/index";
// console.log(VueRouter)
// 1. 定义路由组件.
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [{
                    path: '/',
                    component: () => import('@/view/letter'),
                    meta: {
                              // 页面标题title
                              title: 'Letter'
                    }
          },
          // {
          //   path: '/form',
          //   component: () => import('@/view/form'),
          //   meta: {
          //     // 页面标题title
          //     title: 'Sign Up'
          //   }
          // },
          // {
          //   path: '/gift',
          //   component: () => import('@/view/gift'),
          //   meta: {
          //     // 页面标题title
          //     title: 'Gift'
          //   }
          // },
          // {
          //   path: '/support',
          //   component: () => import('@/view/support'),
          //   meta: {
          //     // 页面标题title
          //     title: 'Support'
          //   }
          // },
          {
                    path: '/letter',
                    component: () => import('@/view/letter'),
                    meta: {
                              // 页面标题title
                              title: 'Letter'
                    }
          }
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
          // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
          // history: createWebHashHistory (),
          history: createWebHistory(),
          routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from, next) => {
          /* 路由发生变化修改页面title */
          if (to.meta.title) {
                    document.title = to.meta.title
          }
          console.log(to)
          let data = {
                    path: to.path
          }
          log(data);
          next()
})

export default router